import { IBankAccountTransfer } from 'src/service/service-bank'

export function generateOFX(
  data: IBankAccountTransfer[],
  actualBalance: number,
): string {
  const header = `OFXHEADER:100
DATA:OFXSGML
VERSION:102
SECURITY:NONE
ENCODING:USASCII
CHARSET:1252
COMPRESSION:NONE
OLDFILEUID:NONE
NEWFILEUID:NONE
`
  const now = new Date()
  const formattedNow =
    now
      .toISOString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14) + '[-03:EST]'
  const bankCode = data[0].bankAccount.bankNumber
  const accountNumber = data[0].bankAccount.accountNumber
  const accountType = data[0].bankAccount.accountType

  const sortedTransactions = data.sort(
    (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  )
  const firstTransactionDate = sortedTransactions[0].date
  const lastTransactionDate =
    sortedTransactions[sortedTransactions.length - 1].date

  const formattedStartDate =
    new Date(firstTransactionDate)
      .toString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14) + '[-03:EST]'
  const formattedEndDate =
    new Date(lastTransactionDate)
      .toString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14) + '[-03:EST]'

  const transactions = data
    .map((transaction: IBankAccountTransfer) => {
      const formattedDate =
        new Date(transaction.date)
          .toString()
          .replace(/[-:.T]/g, '')
          .slice(0, 14) +
        '[-03:EST]' +
        '[-03:EST]'
      const trnAmt =
        transaction.type === 'DEBIT'
          ? `-${Number(transaction.amount).toFixed(2)}`
          : `${Number(transaction.amount).toFixed(2)}`
      return `<STMTTRN>
                <TRNTYPE>${transaction.type}</TRNTYPE>
                <DTPOSTED>${formattedDate}</DTPOSTED>
                <TRNAMT>${trnAmt}</TRNAMT>
                <FITID>${transaction?.externalId ?? transaction?.id}</FITID>
                <MEMO>${transaction.transferCompleteDescription}</MEMO>
                </STMTTRN>`
    })
    .join('\n')

  const body = `<OFX>
                    <SIGNONMSGSRSV1>
                    <SONRS>
                    <STATUS>
                    <CODE>0</CODE>
                    <SEVERITY>INFO</SEVERITY>
                    </STATUS>
                    <DTSERVER>${formattedNow}</DTSERVER>
                    <LANGUAGE>POR</LANGUAGE>
                    </SONRS>
                    </SIGNONMSGSRSV1>
                    <BANKMSGSRSV1>
                    <STMTTRNRS>
                    <CURDEF>BRL</CURDEF>
                    <BANKACCTFROM>
                    <BANKID>${bankCode}</BANKID>
                    <ACCTID>${accountNumber}</ACCTID>
                    <ACCTTYPE>${accountType}</ACCTTYPE>
                    </BANKACCTFROM>
                    <BANKTRANLIST>
                    <DTSTART>${formattedStartDate}</DTSTART>
                    <DTEND>${formattedEndDate}</DTEND>
                    ${transactions}
                    </BANKTRANLIST>
                    <LEDGERBAL>
                    <BALAMT>${actualBalance}</BALAMT>
                    <DTASOF>${formattedNow}</DTASOF>
                    </LEDGERBAL>
                    </STMTTRNRS>
                    </BANKMSGSRSV1>
                </OFX>`

  return header + body
}
