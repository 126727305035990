import React, { useCallback, useEffect, useState } from 'react'
import {
  Stack,
  Typography,
  Button,
  Checkbox,
  useMediaQuery,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import {
  UndoOutlined as UndoOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  AddOutlined as AddOutlinedIcon,
  FilterListOutlined as FilterListOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from '@mui/icons-material'
import { BaseInput } from '../base-component/base-input'
import { ColorCircle } from '../base-component/colored-circle'
import {
  CompanyAccountEntryConciliationStatusEnum,
  ICompanyAccountEntriesResponse,
} from 'src/service/service-company'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'
import { BaseTooltip } from '../base-component/base-tooltip'
import { format } from 'date-fns'
import { ModalFullContext } from '../modal/modal-provider'
import { EditConciliatorEntryModal } from '../modal/conciliator/modal-edit-conciliator-entry'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'

export interface GroupedData {
  title: string
  data: ICompanyAccountEntriesResponse[]
}

export type CompanyUserCardProps = {
  data: GroupedData[]
  title: string
  type: 'debit' | 'credit'
  selectedOptions: string[]
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>
  handleDespise: (selectedItens: string[]) => void
  handleRecover: (selectedItens: string[]) => void
  handleEditEntry: (
    entryData: Partial<ICompanyAccountEntriesResponse>,
  ) => Promise<void>
}

export const EntryFlowComponent = ({
  data,
  title,
  selectedOptions,
  setSelectedOptions,
  handleDespise,
  handleRecover,
  handleEditEntry,
}: CompanyUserCardProps) => {
  const { ShowModal, HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const isExtraSmallScreen = useMediaQuery('(max-width: 1024px)')
  const isSmallScreen = useMediaQuery('(max-width: 1280px)')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [searchText, setSearchText] = useState<string>('')
  const [filteredData, setFilteredData] = useState<GroupedData[]>(data)

  // Debounce function
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout

    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        func(...args)
      }, delay)
    }
  }

  const getDescription = (entry: ICompanyAccountEntriesResponse): string => {
    // Inicializa a descrição como um array para evitar strings vazias
    const descriptionParts: string[] = []

    if (!entry.historic) {
      return ' - '
    }

    // Divide o campo history pelos pipes ('|')
    const historyFields = entry.historic.split('|')

    // Mapeia cada campo e adiciona a descrição correspondente ao array
    historyFields.forEach((field) => {
      switch (field.trim().toLowerCase()) {
        case 'data':
          descriptionParts.push(
            `Data: ${format(new Date(entry.date), 'dd/MM/yyyy')}`,
          )
          break
        case 'valor':
          descriptionParts.push(`Valor: ${formatMoney(Number(entry.value))}`)
          break
        case 'descricao':
          if (entry.description) {
            descriptionParts.push(`Descrição: ${entry.description}`)
          }
          break
        case 'credito':
          if (entry.credit !== undefined) {
            descriptionParts.push(`Crédito: ${entry.credit}`)
          }
          break
        case 'debito':
          if (entry.debit !== undefined) {
            descriptionParts.push(`Débito: ${entry.debit}`)
          }
          break
        default:
          // Ignora campos não especificados
          break
      }
    })

    // Junta todos os elementos de descriptionParts com ' - ' e retorna
    return descriptionParts.join(' - ')
  }

  const getTooltip = (entry: ICompanyAccountEntriesResponse) => {
    let description = 'Sem cliente ou fornecedor informado'
    if (
      entry.bankTransferPartie &&
      (entry.bankTransferPartie?.name ||
        entry?.bankTransferPartie?.document ||
        entry?.bankTransferPartie?.type)
    ) {
      if (entry?.bankTransferPartie?.document) {
        if (entry?.bankTransferPartie?.document?.length > 11) {
          const formattedDocument = entry?.bankTransferPartie?.document
          description = `Cliente Fornecedor: ${formattedDocument}`
        } else {
          const formattedDocument = entry?.bankTransferPartie?.document
          description = ` ${formattedDocument}`
        }
        if (entry?.bankTransferPartie?.name) {
          description += ` ${entry?.bankTransferPartie?.name}`
        }
      } else if (entry?.bankTransferPartie?.name) {
        description = `Cliente Fornecedor: ${entry?.bankTransferPartie.name}`
      }
    }

    return description
  }

  const handleSearch = (text: string) => {
    setSearchText(text)
  }

  const debouncedSearch = useCallback(
    debounce((text: string) => {
      if (!text) {
        setFilteredData(data)
        setIsLoading(false)
      } else {
        setIsLoading(true)
        const lowercasedText = text.toLowerCase()
        const newData = data
          .map((item) => ({
            ...item,
            data: item.data.filter(
              (entry) =>
                getDescription(entry).toLowerCase().includes(lowercasedText) ||
                getTooltip(entry).toLowerCase().includes(lowercasedText),
            ),
          }))
          .filter((item) => item.data.length > 0)
        setIsLoading(false)
        setFilteredData(newData)
      }
    }, 2000),
    [data],
  )

  useEffect(() => {
    debouncedSearch(searchText)
  }, [searchText, debouncedSearch])

  const handleCheckboxChange = (id: string) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(id)
        ? prevSelectedOptions.filter((option) => option !== id)
        : [...prevSelectedOptions, id],
    )
  }

  const getColorByStatus = (
    status: CompanyAccountEntryConciliationStatusEnum,
  ) => {
    let color = '#DB2D25'
    switch (status) {
      case CompanyAccountEntryConciliationStatusEnum.CONCILIATED:
        color = '#03B575'
        break

      case CompanyAccountEntryConciliationStatusEnum.CONCILIATED_PARTIAL:
        color = '#E7B92D'
        break
      case CompanyAccountEntryConciliationStatusEnum.DESPISED:
        color = '#B9BFC7'
        break
      case CompanyAccountEntryConciliationStatusEnum.NOT_CONCILIATED:
        color = '#DB2D25'
        break
      default:
        color = '#DB2D25'
        break
    }
    return color
  }

  const veriFySelectedStatus = () => {
    if (selectedOptions?.length > 0) {
      const haveDespised = filteredData?.some((f) =>
        f.data.some(
          (item) =>
            selectedOptions.includes(item.id) &&
            item.status === CompanyAccountEntryConciliationStatusEnum.DESPISED,
        ),
      )

      if (haveDespised) {
        return 'Recuperar'
      }
    }

    return 'Desprezar'
  }

  const handleEditItem = (item: ICompanyAccountEntriesResponse) => {
    if (item.status === CompanyAccountEntryConciliationStatusEnum.CONCILIATED) {
      return ShowToast('error', 'Registros conciliados não podem ser editados.')
    }

    return ShowModal({
      content: (
        <EditConciliatorEntryModal
          data={item}
          handleCancelAction={() => HideModal()}
          handleConfirmAction={handleEditEntry}
          buttonTitle="Editar"
          key={item.id}
        />
      ),
      title: 'Editar lançamento',
      closeOnBackgroundClick: true,
    })
  }

  return (
    <Stack
      width="100%"
      maxWidth={isSmallScreen ? '35vw' : 'auto'}
      height="100%"
      maxHeight="500px"
      sx={{
        backgroundColor: '#ffffff',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
      }}
    >
      <Stack
        width="100%"
        height="64px"
        sx={{
          backgroundColor: '#4865CC',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
      >
        <Stack
          width="95%"
          padding="8px"
          direction="row"
          alignItems="center"
          gap={isSmallScreen ? '12px' : '24px'}
        >
          <Typography
            fontWeight="700"
            marginLeft="12px"
            fontSize={isSmallScreen ? '14px' : '16px'}
            color="#ffffff"
            textAlign="center"
          >
            {title}
          </Typography>

          <Stack
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            gap={isSmallScreen ? '12px' : '24px'}
            direction="row"
          >
            <Stack
              width={
                isExtraSmallScreen ? '120px' : isSmallScreen ? '180px' : '280px'
              }
              height="40px"
              justifyContent="center"
              alignItems="center"
              borderRadius="50px"
              border="0px solid #ffffff"
              sx={{ backgroundColor: 'white' }}
            >
              <BaseInput
                fullWidth
                type="text"
                label=""
                labelBackground="transparent"
                setState={handleSearch}
                value={searchText}
                error={null}
                iconEnd={<SearchOutlinedIcon />}
              />
            </Stack>
            <Button
              variant="outlined"
              sx={{
                minWidth: isSmallScreen ? '80px' : '100px',
                maxWidth: isSmallScreen ? '80px' : 'auto',
                height: '40px',
                color: '#FFFFFF',
                border: '1px solid #FFFFFF',
              }}
              onClick={() => console.log('press me')}
            >
              <Typography
                fontWeight="500"
                fontSize={isSmallScreen ? '12px' : '14px'}
              >
                Período
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack width="100%" height="56px" sx={{ backgroundColor: '#F9FDFE' }}>
        <Stack
          width="calc(100% - 12px)"
          padding="12px"
          alignItems="center"
          justifyContent="center"
          direction="row"
          gap={isSmallScreen ? '12px' : '24px'}
        >
          {[
            {
              icon: (
                <UndoOutlinedIcon
                  sx={{
                    color: selectedOptions?.length > 0 ? '#4865CC' : '#B9BFC7',
                  }}
                />
              ),
              label: 'Desconciliar',
              color: selectedOptions?.length > 0 ? '#4865CC' : '#B9BFC7',
            },
            {
              icon: (
                <DeleteOutlinedIcon
                  sx={{
                    color: selectedOptions?.length > 0 ? '#4865CC' : '#B9BFC7',
                  }}
                />
              ),
              label: veriFySelectedStatus(),
              color: selectedOptions?.length > 0 ? '#4865CC' : '#B9BFC7',
            },
            {
              icon: <AddOutlinedIcon />,
              label: 'Criar lançamento',
              color: '#4865CC',
            },
          ].map(({ icon, label, color }, index) => (
            <Stack
              key={index}
              alignItems="center"
              width={color ? '130px' : '140px'}
              direction="row"
              gap="8px"
              onClick={() => {
                if (label === 'Criar lançamento') {
                  return ShowModal({
                    content: (
                      <EditConciliatorEntryModal
                        data={null}
                        handleCancelAction={() => null}
                        handleConfirmAction={() => null}
                        buttonTitle="Criar"
                      />
                    ),
                    title: 'Criar lançamento',
                    closeOnBackgroundClick: false,
                    closeButton: false,
                  })
                }
                if (label === 'Desprezar') {
                  return handleDespise(selectedOptions)
                }
                if (label === 'Recuperar') {
                  return handleRecover(selectedOptions)
                }

                return null
              }}
              sx={{ cursor: 'pointer', color: color || '#B9BFC7' }}
            >
              {icon}
              <Typography
                fontWeight="500"
                fontSize={isSmallScreen ? '12px' : '14px'}
                lineHeight="16.41px"
              >
                {label}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack
        width="100%"
        height="56px"
        sx={{
          backgroundColor: '#5ED1A2',
        }}
      >
        <Stack
          id="header"
          width="100%"
          padding="12px"
          paddingX="24px"
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          gap={isSmallScreen ? '12px' : '56px'}
          sx={{
            color: '#FFFFFF',
          }}
        >
          {['Data', 'Valor', 'Histórico'].map((text, index) => (
            <Stack
              key={index}
              width="calc(100% / 3)"
              direction="row"
              gap={isSmallScreen ? '12px' : '24px'}
              alignItems="center"
              justifyContent="flex-start"
              sx={{ cursor: 'pointer' }}
            >
              <Typography
                fontWeight="500"
                fontSize={isSmallScreen ? '12px' : '14px'}
              >
                {text}
              </Typography>
              <FilterListOutlinedIcon />
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack
        id="content"
        width="100%"
        height="calc(100% - 176px)"
        sx={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        {isLoading && (
          <Stack
            width="100%"
            height="200px"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        )}
        {!isLoading &&
          filteredData.map((item, index) => (
            <Stack key={index} width="100%" borderBottom="1px dashed #CCCCCC">
              <Stack width="100%" padding="12px" gap="24px">
                <Typography
                  fontWeight="600"
                  fontSize={isSmallScreen ? '12px' : '14px'}
                  lineHeight="19.07px"
                >
                  {item.title}
                </Typography>

                {item.data.map((itemData, i) => (
                  <Stack
                    key={i}
                    width="calc(100% - 24px)"
                    gap={isSmallScreen ? '10px' : '12px'}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      opacity:
                        itemData.status ===
                        CompanyAccountEntryConciliationStatusEnum.DESPISED
                          ? 0.6
                          : 1,
                    }}
                  >
                    <Stack
                      gap="12px"
                      direction="row"
                      alignItems="center"
                      sx={{ flexBasis: '30%', flexGrow: 1 }}
                    >
                      <Checkbox
                        sx={{
                          color: '#4865CC',
                          '&.Mui-checked': {
                            color: '#4865CC',
                          },
                        }}
                        checked={selectedOptions.includes(itemData.id)}
                        onChange={() => handleCheckboxChange(itemData.id)}
                      />
                      <ColorCircle
                        color={getColorByStatus(itemData.status)}
                        width={8}
                        height={8}
                      />
                      <Typography
                        fontWeight={400}
                        fontSize={isSmallScreen ? '12px' : '14px'}
                        lineHeight="19.07px"
                      >
                        {format(itemData.bankTransferDate, 'dd/MM/yyyy HH:mm')}
                      </Typography>
                    </Stack>
                    <Stack
                      alignItems="center"
                      sx={{ flexBasis: '20%', flexGrow: 1 }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={isSmallScreen ? '12px' : '14px'}
                      >
                        {formatMoney(Number(itemData.value))}
                      </Typography>
                    </Stack>
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      gap="12px"
                      sx={{ flexBasis: '50%', flexGrow: 1 }}
                    >
                      {!isSmallScreen && (
                        <Tooltip title={getDescription(itemData)}>
                          <Typography
                            fontWeight={400}
                            fontSize={isSmallScreen ? '12px' : '14px'}
                            lineHeight="19.07px"
                            sx={{
                              maxWidth: '260px', // Limita a largura a 200px
                              overflow: 'hidden', // Oculta o excesso de texto
                              whiteSpace: 'nowrap', // Garante que o texto fique em uma linha
                              textOverflow: 'ellipsis', // Adiciona "..." ao final do texto
                            }}
                          >
                            {getDescription(itemData)}
                          </Typography>
                        </Tooltip>
                      )}
                      <Stack
                        gap="12px"
                        direction="row"
                        alignItems="center"
                        width={isSmallScreen ? '100%' : 'auto'}
                        justifyContent={
                          isSmallScreen ? 'flex-end' : 'flex-start'
                        }
                        sx={{ cursor: 'pointer' }}
                      >
                        <BaseTooltip
                          message={
                            isSmallScreen
                              ? `${getDescription(itemData)} - ${getTooltip(itemData)}`
                              : getTooltip(itemData)
                          }
                          key={itemData.id}
                        />
                        <Stack onClick={() => handleEditItem(itemData)}>
                          <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Stack>
  )
}
