import { Stack } from '@mui/material'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { LoginPage } from 'src/page/authentication/page-authentication-login'
import { PageCompanyReports } from 'src/page/company-reports/page-company-reports'
import { MultiCompanyPanelPage } from 'src/page/multi-company-home/company-panel'
import { ImportCSVTransactionSystemicAccountPage } from 'src/page/systemic-accounts/page-systemic-account-csv-import'
import { ListSystemicAccountPage } from 'src/page/systemic-accounts/page-systemic-account-list'
import { UpdateSystemicAccountPage } from 'src/page/systemic-accounts/page-systemic-account-update'
import { Api } from 'src/service/service-api'
import { AuthenticationApiRemoteService } from 'src/service/service-authentication'
import { BankApiRemoteService } from 'src/service/service-bank'
import { LoginUseCase } from 'src/usecase/authentication/usecase-authentication-form-login'
import { RegisterUseCase } from 'src/usecase/authentication/usecase-authentication-form-register'
import { CreateAccountStepTwopluggyFinalFlowUseCase } from 'src/usecase/bank-account/usecase-account-create-step-two-pluggy-final-flow'
import { CreateAccountStepTwopluggyInititalFlowUseCase } from 'src/usecase/bank-account/usecase-account-create-step-two-pluggy-initial-flow'
import { DeleteBankAccountUseCase } from 'src/usecase/bank-account/usecase-account-delete'
import { ListCompanyAccountUseCase } from 'src/usecase/bank-account/usecase-account-find-one'
import { AccountGetCSVColumnsFileUseCase } from 'src/usecase/bank-account/usecase-account-get-csv-columns'
import { AccountCSVImportTransactionUseCase } from 'src/usecase/bank-account/usecase-account-import-csv-transactions'
import { ListAccountsUseCase } from 'src/usecase/bank-account/usecase-account-list'
import { UpdateAccountOpenFinanceConnectUseCase } from 'src/usecase/bank-account/usecase-account-open-finance-connect'
import { UpdateAccountOpenFinanceDisconnectUseCase } from 'src/usecase/bank-account/usecase-account-open-finance-disconnect'
import { AccountPreviewCSVImportTransactionUseCase } from 'src/usecase/bank-account/usecase-account-preview-csv-import-transaction'
import { UpdateAccountUseCase } from 'src/usecase/bank-account/usecase-account-update'
import { AccountImportOfxFileUseCase } from 'src/usecase/bank-account/usecase-account-upload-file-ofx'
import { ListBanksUseCase } from 'src/usecase/bank/usecase-bank-list'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { LeaveCompanyUseCase } from 'src/usecase/company/usecase-company-leave'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'
import { Sidebar } from '../component/sidebar'
import { BackupCodePage } from '../page/authentication/page-authentication-backupcode'
import { ContactUsPage } from '../page/authentication/page-authentication-contact-us'
import { NewBackupCodeAfterRecoveryPage } from '../page/authentication/page-authentication-new-pin-after-recovery'
import { RecoveryWith2FAPage } from '../page/authentication/page-authentication-recovery-2fa'
import { RecoveryWithEmailPage } from '../page/authentication/page-authentication-recovery-email'
import { RecoveryWithEmailCompletePage } from '../page/authentication/page-authentication-recovery-email-complete'
import { RecoveryWithPinPage } from '../page/authentication/page-authentication-recovery-pin'
import { Recovery2FAPage } from '../page/authentication/page-authentication-recovery-setup-2fa'
import { RecoveryEmailPage } from '../page/authentication/page-authentication-recovery-setup-email'
import { RegisterPage } from '../page/authentication/page-authentication-register'
import { CreateCompanyPage } from '../page/company/page-company-create'
import { CreateCompanyMassImportPage } from '../page/company/page-company-mass-create'
import { CreateSystemicAccountPage } from '../page/systemic-accounts/page-systemic-account-create'
import { AccountApiRemoteService } from '../service/service-account'
import { CompanyApiRemoteService } from '../service/service-company'
import { UserApiRemoteService } from '../service/service-user'
import { ContactUsUseCase } from '../usecase/authentication/usecase-authentication-form-contact-us'
import { RecoveryEmailUseCase } from '../usecase/authentication/usecase-authentication-form-recovery-email'
import { Recovery2FAFinishUseCase } from '../usecase/authentication/usecase-authentication-form-recovery-finish-2fa'
import { Recovery2FAInitUseCase } from '../usecase/authentication/usecase-authentication-form-recovery-init-2fa'
import { RecoveryWith2FAUseCase } from '../usecase/authentication/usecase-authentication-form-recovery-with-2fa'
import { RecoveryWithEmailUseCase } from '../usecase/authentication/usecase-authentication-form-recovery-with-email'
import { RecoveryWithEmailCompleteUseCase } from '../usecase/authentication/usecase-authentication-form-recovery-with-email-complete'
import { RecoveryWithPinUseCase } from '../usecase/authentication/usecase-authentication-form-recovery-with-pin'
import { CreateAccountStepFourUseCase } from '../usecase/bank-account/usecase-account-create-step-four-form'
import { CreateAccountStepThreeUseCase } from '../usecase/bank-account/usecase-account-create-step-tree-form'
import { CreateCompanyUseCase } from '../usecase/company/usecase-company-form-create'
import { CreateCompanyMassImportUseCase } from '../usecase/company/usecase-company-mass-import-create'
import { CreateCompanyValidateFileUseCase } from '../usecase/company/usecase-company-validate-file'
import { UserOwnDataUseCase } from '../usecase/user/usecase-user-own-data'

import { ClassificationRulesList } from 'src/page/classification-rules/page-classification-rules-list'
import { ListUserCompaniesAccountsUseCase } from 'src/usecase/bank-account/usecase-user-companies-list'
import { ListTransfersUseCase } from 'src/usecase/transfers/list-transfers'
import { ListCashFlowUseCase } from 'src/usecase/cashflow/list-cashflow'
import { ListCompanyClassificationRulesUseCase } from 'src/usecase/company/usecase-company-classification-rules-list'
import { CreateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-create-classification-rule'
import { DeleteClassificationRuleUseCase } from 'src/usecase/company/usecase-company-delete-classification-rule'
import { UpdateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-update-classification-rule'
import { CompanyDetailsPage } from 'src/page/company/page-company-details'
import { ClassifierPage } from 'src/page/classifier/page-classifier'
import { ReplyCompanyClassificationRuleUseCase } from 'src/usecase/company/usecase-company-reply-classification-rule'
import { ListAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-list'
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update'
import { ListCompanyVendorsUseCase } from 'src/usecase/classifier/usecase-company-vendors-list'
import { ConciliatorList } from 'src/page/conciliator/page-conciliator-list'
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list'
import { UserProfilePage } from 'src/page/profile/page-my-profile'
import { UserGetProfileUseCase } from 'src/usecase/user/usecase-user-profile'
import { UpdateProfileUseCase } from 'src/usecase/user/usecase-profile-update'
import { CompanyEditPage } from 'src/page/company/page-company-edit'
import { CompanyUseDetailsUseCase } from 'src/usecase/company/usecase-company-details'
import { UpdateCompanyUseCase } from 'src/usecase/company/usecase-company-update'
import { CompanyUsersEditPage } from 'src/page/company/page-edit-company-users'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'
import { SyncAccountOpenFinanceUseCase } from 'src/usecase/company/usecase-refresh-open-finance'
import { ListTransfersImportHistoryUseCase } from 'src/usecase/transfers/list-transfer-import-history'
import { BankAccountExtractList } from 'src/page/company/page-company-account-extract-list'
import { DeleteTransferImportUseCase } from 'src/usecase/transfers/delete-transfer-import'
import { ClassifyAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-classify'
import { ListAccountingEntriesToConciliatorUseCase } from 'src/usecase/conciliator/usecase-accounting-entries-conciliator-list'
import { CompanyAccountGetActualOpenFinanceBalanceUseCase } from 'src/usecase/bank-account/usecase-account-get-open-finance-actual-balance'
import { CreateCompaniesManyUsersUseCase } from 'src/usecase/company/usecase-companies-add-many-users'
import { ListCompanyPlansUseCase } from 'src/usecase/company/usecase-company-plans-list'
import { ConciliateAccountingEntriesUseCase } from 'src/usecase/conciliator/usecase-accounting-entries-conciliate'
import { ListAccountingEntriesBankBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-bank-balance-list'
import { ListAccountingEntriesBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-balance-list'
import { ListAccountingEntriesCashflowUseCase } from 'src/usecase/classifier/usecase-accounting-entries-cashflow-list'

import { useEffect, useRef, useState } from 'react'
import { SupportPage } from 'src/page/support/page-support'
import { CreateManualAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-create'
import { TransferCompanyOwnerUseCase } from 'src/usecase/company/usecase-company-transfer-owner'
import { AccountingEntryDismemberUseCase } from 'src/usecase/classifier/usecase-accounting-entries-dismember'
import { ListUserSubscriptionsPlansUseCase } from 'src/usecase/company/usecase-company-subscriptions'
import { CreateSubscriptionPage } from 'src/page/subscription/page-subscription-create'
import { SubscriptionApiRemoteService } from 'src/service/service-subscription'
import { CreateSubscriptionUseCase } from 'src/usecase/company/usecase-subscription-create'

const isAuthenticated = () => {
  return localStorage.getItem('auth') !== null
}

const PrivateRoute = ({ element, hideSidebar = false }: any) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const [sidebarWidth, setSidebarWidth] = useState(0)

  useEffect(() => {
    if (sidebarRef.current) {
      setSidebarWidth(sidebarRef.current.offsetWidth)
    }
  }, [hideSidebar])

  return isAuthenticated() ? (
    <Stack
      flexDirection="row"
      width="100%"
      height="100vh"
      sx={{ overflowX: 'hidden' }}
    >
      {!hideSidebar && (
        <Stack ref={sidebarRef} sx={{ flex: '0 0 auto' }}>
          <Sidebar />
        </Stack>
      )}
      <Stack flex={1} height="100vh">
        {element}
      </Stack>
    </Stack>
  ) : (
    <Navigate to="/auth/sign-in" replace />
  )
}

const Router = createBrowserRouter([
  // public routes
  {
    path: '/',
    element: (
      <LoginPage
        useCase={
          new LoginUseCase(new AuthenticationApiRemoteService(Api.axios))
        }
        userOwnDataUseCase={
          new UserOwnDataUseCase(new UserApiRemoteService(Api.axios))
        }
      />
    ),
  },
  // AUTH
  {
    path: '/auth/sign-in',
    element: (
      <LoginPage
        useCase={
          new LoginUseCase(new AuthenticationApiRemoteService(Api.axios))
        }
        userOwnDataUseCase={
          new UserOwnDataUseCase(new UserApiRemoteService(Api.axios))
        }
      />
    ),
  },
  {
    path: '/auth/sign-up',
    element: (
      <RegisterPage
        useCase={
          new RegisterUseCase(new AuthenticationApiRemoteService(Api.axios))
        }
        loginUsecase={
          new LoginUseCase(new AuthenticationApiRemoteService(Api.axios))
        }
        userOwnData={
          new UserOwnDataUseCase(new UserApiRemoteService(Api.axios))
        }
      />
    ),
  },
  {
    path: '/auth/backup-code',
    element: (
      <BackupCodePage
        userOwnData={
          new UserOwnDataUseCase(new UserApiRemoteService(Api.axios))
        }
      />
    ),
  },
  // AUTH -> RECOVERY SET-UP
  {
    path: '/auth/recovery/setup/email',
    element: (
      <RecoveryEmailPage
        useCase={
          new RecoveryEmailUseCase(
            new AuthenticationApiRemoteService(Api.axios),
          )
        }
      />
    ),
  },
  {
    path: '/auth/recovery/setup/2fa',
    element: (
      <Recovery2FAPage
        useCaseInit={
          new Recovery2FAInitUseCase(
            new AuthenticationApiRemoteService(Api.axios),
          )
        }
        useCaseFinish={
          new Recovery2FAFinishUseCase(
            new AuthenticationApiRemoteService(Api.axios),
          )
        }
      />
    ),
  },

  // AUTH -> RECOVERY
  {
    path: '/auth/recovery/backup-code',
    element: (
      <RecoveryWithPinPage
        useCase={
          new RecoveryWithPinUseCase(
            new AuthenticationApiRemoteService(Api.axios),
          )
        }
        loginUseCase={
          new LoginUseCase(new AuthenticationApiRemoteService(Api.axios))
        }
      />
    ),
  },
  {
    path: '/auth/recovery/2fa',
    element: (
      <RecoveryWith2FAPage
        useCase={
          new RecoveryWith2FAUseCase(
            new AuthenticationApiRemoteService(Api.axios),
          )
        }
      />
    ),
  },
  {
    path: '/auth/recovery/email',
    element: (
      <RecoveryWithEmailPage
        useCase={
          new RecoveryWithEmailUseCase(
            new AuthenticationApiRemoteService(Api.axios),
          )
        }
      />
    ),
  },
  {
    path: '/auth/recovery/email/complete',
    element: (
      <RecoveryWithEmailCompletePage
        useCase={
          new RecoveryWithEmailCompleteUseCase(
            new AuthenticationApiRemoteService(Api.axios),
          )
        }
      />
    ),
  },
  {
    path: '/auth/recovery/backup-code/recovered',
    element: (
      <NewBackupCodeAfterRecoveryPage
        userOwnData={
          new UserOwnDataUseCase(new UserApiRemoteService(Api.axios))
        }
      />
    ),
  },

  // AUTH -> CONTACT
  {
    path: '/contact-us',
    element: <ContactUsPage useCase={new ContactUsUseCase()} />,
  },
  // private routes
  {
    path: '/home',
    element: (
      <PrivateRoute
        element={
          <MultiCompanyPanelPage
            listCompaniesUseCase={
              new ListCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            listClassificationRulesUseCase={
              new ListCompanyClassificationRulesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            addUserToCompanyUseCase={
              new CreateCompanyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listUsersUseCase={
              new UserListUseCase(new UserApiRemoteService(Api.axios))
            }
            leaveCompanyUseCase={
              new LeaveCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            replyCompanyClassificationRuleUseCase={
              new ReplyCompanyClassificationRuleUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            createCompaniesManyUsersUseCase={
              new CreateCompaniesManyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
        hideSidebar={true}
      />
    ),
  },
  {
    path: '/company/new',
    element: (
      <PrivateRoute
        hideSidebar={true}
        element={
          <CreateCompanyPage
            useCase={
              new CreateCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            masImportUseCase={
              new CreateCompanyValidateFileUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listCompanyPlansUseCase={
              new ListCompanyPlansUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listUserSubscriptionsPlansUseCase={
              new ListUserSubscriptionsPlansUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/subscription/new',
    element: (
      <PrivateRoute
        hideSidebar={true}
        element={
          <CreateSubscriptionPage
            useCase={
              new CreateSubscriptionUseCase(
                new SubscriptionApiRemoteService(Api.axios),
              )
            }
            listCompanyPlansUseCase={
              new ListCompanyPlansUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listUserSubscriptionsPlansUseCase={
              new ListUserSubscriptionsPlansUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/new/import',
    element: (
      <PrivateRoute
        hideSidebar={true}
        element={
          <CreateCompanyMassImportPage
            useCase={
              new CreateCompanyMassImportUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listCompanyPlansUseCase={
              new ListCompanyPlansUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId',
    element: (
      <PrivateRoute
        element={
          <CompanyDetailsPage
            listCompaniesUseCase={
              new ListCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            addUserToCompanyUseCase={
              new CreateCompanyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listUsersUseCase={
              new UserListUseCase(new UserApiRemoteService(Api.axios))
            }
            leaveCompanyUseCase={
              new LeaveCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            userAccountsCompaniesUseCase={
              new ListUserCompaniesAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listUserCompaniesAccountsUseCase={
              new ListUserCompaniesAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listTransfersUseCase={
              new ListTransfersUseCase(new BankApiRemoteService(Api.axios))
            }
            listCashFlowUseCase={
              new ListCashFlowUseCase(new BankApiRemoteService(Api.axios))
            }
            getCsvFileColumnsUseCase={
              new AccountGetCSVColumnsFileUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            uploadOFXFile={
              new AccountImportOfxFileUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            syncAccountOpenFinanceUseCase={
              new SyncAccountOpenFinanceUseCase(
                new BankApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/edit',
    element: (
      <PrivateRoute
        element={
          <CompanyEditPage
            companyUseDetailsUseCase={
              new CompanyUseDetailsUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            updateCompanyUseCase={
              new UpdateCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            listCompanyPlansUseCase={
              new ListCompanyPlansUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/users/edit',
    element: (
      <PrivateRoute
        element={
          <CompanyUsersEditPage
            companyUseDetailsUseCase={
              new CompanyUseDetailsUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            addUserToCompanyUseCase={
              new CreateCompanyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listUsersUseCase={
              new UserListUseCase(new UserApiRemoteService(Api.axios))
            }
            transferCompanyOwnerUseCase={
              new TransferCompanyOwnerUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/account/list',
    element: (
      <PrivateRoute
        element={
          <ListSystemicAccountPage
            accountUseCase={
              new ListAccountsUseCase(new AccountApiRemoteService(Api.axios))
            }
            deleteAccountUseCase={
              new DeleteBankAccountUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            uploadOFXFile={
              new AccountImportOfxFileUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            getCsvFileColumnsUseCase={
              new AccountGetCSVColumnsFileUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/account/new',
    element: (
      <PrivateRoute
        element={
          <CreateSystemicAccountPage
            stepTreeUseCase={
              new CreateAccountStepThreeUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            stepFourUseCase={
              new CreateAccountStepFourUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            openFinanceInitialFlowUseCase={
              new CreateAccountStepTwopluggyInititalFlowUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            openFinanceFinalFlowUseCase={
              new CreateAccountStepTwopluggyFinalFlowUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listBanksUseCase={
              new ListBanksUseCase(new BankApiRemoteService(Api.axios))
            }
            getCsvFileColumnsUseCase={
              new AccountGetCSVColumnsFileUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            uploadOFXFile={
              new AccountImportOfxFileUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listLedgerAccountsUseCase={
              new ListLedgerAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            updateUseCase={
              new UpdateAccountUseCase(new AccountApiRemoteService(Api.axios))
            }
            companyAccountGetActualOpenFinanceBalanceUseCase={
              new CompanyAccountGetActualOpenFinanceBalanceUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/account/edit/:accountId',
    element: (
      <PrivateRoute
        element={
          <UpdateSystemicAccountPage
            getAccountCase={
              new ListCompanyAccountUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            updateUseCase={
              new UpdateAccountUseCase(new AccountApiRemoteService(Api.axios))
            }
            listBanksUseCase={
              new ListBanksUseCase(new BankApiRemoteService(Api.axios))
            }
            disconnectOpenFinanceUseCase={
              new UpdateAccountOpenFinanceDisconnectUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            connectOpenFinanceUseCase={
              new UpdateAccountOpenFinanceConnectUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listLedgerAccountsUseCase={
              new ListLedgerAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            openFinanceInitialFlowUseCase={
              new CreateAccountStepTwopluggyInititalFlowUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            openFinanceFinalFlowUseCase={
              new CreateAccountStepTwopluggyFinalFlowUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/classification-rules',
    element: (
      <PrivateRoute
        element={
          <ClassificationRulesList
            listCompaniesUseCase={
              new ListCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            addUserToCompanyUseCase={
              new CreateCompanyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listUsersUseCase={
              new UserListUseCase(new UserApiRemoteService(Api.axios))
            }
            leaveCompanyUseCase={
              new LeaveCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            listClassificationRulesUseCase={
              new ListCompanyClassificationRulesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            createClassificationRuleUseCase={
              new CreateAccountClassificationRuleUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            deleteClassificationRuleUseCase={
              new DeleteClassificationRuleUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            updateClassificationRuleUseCase={
              new UpdateAccountClassificationRuleUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            replyCompanyClassificationRuleUseCase={
              new ReplyCompanyClassificationRuleUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/classifier',
    element: (
      <PrivateRoute
        element={
          <ClassifierPage
            listUserCompaniesAccountsUseCase={
              new ListUserCompaniesAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listAccountingEntriesUseCase={
              new ListAccountingEntriesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            updateAccountingEntriesUseCase={
              new UpdateAccountingEntriesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listCompanyVendorsUserCase={
              new ListCompanyVendorsUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listCompanyDebitsUserCase={
              new ListCompanyDebitUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            classifyAccountingEntriesUseCase={
              new ClassifyAccountingEntriesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listLedgerAccountsUseCase={
              new ListLedgerAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listAccountingEntriesBalanceUseCase={
              new ListAccountingEntriesBalanceUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listAccountingEntriesBankBalanceUseCase={
              new ListAccountingEntriesBankBalanceUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listAccountingEntriesCashflowUseCase={
              new ListAccountingEntriesCashflowUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            createManualAccountingEntriesUseCase={
              new CreateManualAccountingEntriesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            accountingEntryDismemberUseCase={
              new AccountingEntryDismemberUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/account/:accountId/import/extract/csv',
    element: (
      <PrivateRoute
        element={
          <ImportCSVTransactionSystemicAccountPage
            importTransactionsUseCase={
              new AccountCSVImportTransactionUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            previewDataUseCase={
              new AccountPreviewCSVImportTransactionUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/:companyId/conciliator',
    element: (
      <PrivateRoute
        element={
          <ConciliatorList
            listLedgerAccountsUseCase={
              new ListLedgerAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listAccountingEntriesToConciliatorUseCase={
              new ListAccountingEntriesToConciliatorUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            conciliateAccountingEntriesUseCase={
              new ConciliateAccountingEntriesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            updateAccountingEntriesUseCase={
              new UpdateAccountingEntriesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/company/reports',
    element: (
      <PrivateRoute
        hideSidebar={true}
        element={
          <PageCompanyReports
            listCompaniesUseCase={
              new ListCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            listClassificationRulesUseCase={
              new ListCompanyClassificationRulesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            addUserToCompanyUseCase={
              new CreateCompanyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listUsersUseCase={
              new UserListUseCase(new UserApiRemoteService(Api.axios))
            }
            leaveCompanyUseCase={
              new LeaveCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            userAccountsCompaniesUseCase={
              new ListUserCompaniesAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listUserCompaniesAccountsUseCase={
              new ListUserCompaniesAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listTransfersUseCase={
              new ListTransfersUseCase(new BankApiRemoteService(Api.axios))
            }
            listCashFlowUseCase={
              new ListCashFlowUseCase(new BankApiRemoteService(Api.axios))
            }
            replyCompanyClassificationRuleUseCase={
              new ReplyCompanyClassificationRuleUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            createCompaniesManyUsersUseCase={
              new CreateCompaniesManyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  // Home com uma empresa selecionada
  {
    path: '/company/home/:companyId',
    element: (
      <PrivateRoute
        element={
          <PageCompanyReports
            listCompaniesUseCase={
              new ListCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            listClassificationRulesUseCase={
              new ListCompanyClassificationRulesUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            addUserToCompanyUseCase={
              new CreateCompanyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            listUsersUseCase={
              new UserListUseCase(new UserApiRemoteService(Api.axios))
            }
            leaveCompanyUseCase={
              new LeaveCompanyUseCase(new CompanyApiRemoteService(Api.axios))
            }
            userAccountsCompaniesUseCase={
              new ListUserCompaniesAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listUserCompaniesAccountsUseCase={
              new ListUserCompaniesAccountsUseCase(
                new AccountApiRemoteService(Api.axios),
              )
            }
            listTransfersUseCase={
              new ListTransfersUseCase(new BankApiRemoteService(Api.axios))
            }
            listCashFlowUseCase={
              new ListCashFlowUseCase(new BankApiRemoteService(Api.axios))
            }
            replyCompanyClassificationRuleUseCase={
              new ReplyCompanyClassificationRuleUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
            createCompaniesManyUsersUseCase={
              new CreateCompaniesManyUsersUseCase(
                new CompanyApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/user/profile',
    element: (
      <PrivateRoute
        hideSidebar={true}
        element={
          <UserProfilePage
            userGetProfileUseCase={
              new UserGetProfileUseCase(new UserApiRemoteService(Api.axios))
            }
            updateProfileUseCase={
              new UpdateProfileUseCase(new UserApiRemoteService(Api.axios))
            }
            recoveryEmailUseCase={
              new RecoveryEmailUseCase(
                new AuthenticationApiRemoteService(Api.axios),
              )
            }
            useCaseInit={
              new Recovery2FAInitUseCase(
                new AuthenticationApiRemoteService(Api.axios),
              )
            }
            useCaseFinish={
              new Recovery2FAFinishUseCase(
                new AuthenticationApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
  {
    path: '/support',
    element: <PrivateRoute hideSidebar={true} element={<SupportPage />} />,
  },
  {
    path: '/company/:companyId/account/:accountId/extract/list',
    element: (
      <PrivateRoute
        element={
          <BankAccountExtractList
            listTransfersImportHistoryUseCase={
              new ListTransfersImportHistoryUseCase(
                new BankApiRemoteService(Api.axios),
              )
            }
            deleteTransferImportUseCase={
              new DeleteTransferImportUseCase(
                new BankApiRemoteService(Api.axios),
              )
            }
          />
        }
      />
    ),
  },
])

// eslint-disable-next-line import/no-default-export
export default Router
