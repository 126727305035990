import { ArrowBack, ArrowDropDown } from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers-pro'
import {
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BankAccountCard } from 'src/component/bank/account-card'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { BaseMultipleSelectInput } from 'src/component/base-component/base-multiple-select'
import { BasePageSubtitle } from 'src/component/base-component/base-page-subtitle'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { CashFlow } from 'src/component/financial/cash-flow'
import { Extract, ExtractProps } from 'src/component/financial/extract'
import { DefaultHeader } from 'src/component/header/header'
import { MenuDotIcon } from 'src/component/icons/menu-dot'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { ModalReportsExport } from 'src/component/modal/modal-reports-export'
import { ModalSelectPriod } from 'src/component/modal/modal-select-period'
import { BankAccountTransferMethodEnum } from 'src/service/service-bank'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'
import { ListUserCompaniesAccountsUseCase } from 'src/usecase/bank-account/usecase-user-companies-list'
import { ListCashFlowUseCase } from 'src/usecase/cashflow/list-cashflow'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { ListCompanyClassificationRulesUseCase } from 'src/usecase/company/usecase-company-classification-rules-list'
import { LeaveCompanyUseCase } from 'src/usecase/company/usecase-company-leave'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { ReplyCompanyClassificationRuleUseCase } from 'src/usecase/company/usecase-company-reply-classification-rule'
import { ListTransfersUseCase } from 'src/usecase/transfers/list-transfers'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'
import { generateCustomPDF } from 'src/utils/generateCustomPDF'
import { generateXLS } from 'src/utils/generateXLS'
import { useCompany } from '../multi-company-home/useCompany'
import { useUserAccount } from '../systemic-accounts/useUserAccount'
import { CompanySection } from './styles'
import { useCashFlow } from './useCashFlow'
import { useTransfers } from './useTransfers'
import { CreateCompaniesManyUsersUseCase } from 'src/usecase/company/usecase-companies-add-many-users'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface MultiCompanyReportsPageProps {
  listCompaniesUseCase: ListCompanyUseCase
  listClassificationRulesUseCase: ListCompanyClassificationRulesUseCase
  addUserToCompanyUseCase: CreateCompanyUsersUseCase
  listUsersUseCase: UserListUseCase
  leaveCompanyUseCase: LeaveCompanyUseCase
  userAccountsCompaniesUseCase: ListUserCompaniesAccountsUseCase
  listUserCompaniesAccountsUseCase: ListUserCompaniesAccountsUseCase
  listTransfersUseCase: ListTransfersUseCase
  listCashFlowUseCase: ListCashFlowUseCase
  replyCompanyClassificationRuleUseCase: ReplyCompanyClassificationRuleUseCase
  createCompaniesManyUsersUseCase: CreateCompaniesManyUsersUseCase
}

export interface Account {
  id: string
  name: string
  amount: string
  agency: string
  account: string
  bankName: string
  bankLogo?: string
  openFinance?: boolean
}

export interface CompanyAccount {
  id: string
  label: string
  subOptions: Account[]
}

export function PageCompanyReports(props: MultiCompanyReportsPageProps): any {
  const { ShowModal, HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const navigate = useNavigate()
  const [showResults, setShowResults] = useState(false)
  const [tab, setTab] = useState<number>(0)
  const [companiesAccounts, setCompaniesAccounts] = useState<CompanyAccount[]>(
    [],
  )
  const [accountsSelected, setAccountsSelected] = useState<string[]>([])
  const [companiesSelected, setCompaniesSelected] = useState<string[]>([])
  const [initialDate, setInitialDate] = useState<Date | undefined>(
    startOfMonth(subMonths(new Date().setHours(-3), 2)),
  )
  const [finalDate, setFinalDate] = useState<Date | undefined>(
    endOfMonth(startOfDay(new Date().setHours(-3))),
  )
  const [filterDates, setFilterDates] = useState<string[] | undefined>([
    startOfMonth(new Date()).toString(),
    endOfMonth(new Date()).toString(),
  ])
  const [extract, setExtract] = useState({} as ExtractProps)

  const { companies, handleListUserCompanies } = useCompany(
    props.listCompaniesUseCase,
    props.addUserToCompanyUseCase,
    props.listUsersUseCase,
    props.leaveCompanyUseCase,
    props.createCompaniesManyUsersUseCase,
  )

  const { handleListUserCompaniesAccounts, userCompaniesAccounts } =
    useUserAccount(props.listUserCompaniesAccountsUseCase)

  const { handleListTransfers, transfers, balance } = useTransfers(
    props.listTransfersUseCase,
  )

  const {
    cashflow,
    handleListCashFlow,
    cashflowConsolidate,
    variationData,
    minVariation,
    maxVariation,
    categoriesVariations,
  } = useCashFlow(props.listCashFlowUseCase)

  useEffect(() => {
    handleListUserCompanies()
  }, [])

  useEffect(() => {
    if (companies.length) {
      handleListUserCompaniesAccounts(companies?.map((company) => company.id))
    }
  }, [companies])

  const handleFilterPeriod = async (dates: string[]) => {
    if (dates.length > 0) {
      await handleListTransfers({
        accountsIds: accountsSelected,
        date: dates,
      })
      setFilterDates(dates)
    } else {
      await handleListTransfers({ accountsIds: accountsSelected })
      setInitialDate(undefined)
      setFinalDate(undefined)
      setFilterDates([])
    }
    HideModal()
  }

  const handleOpenPeriodModal = () => {
    ShowModal({
      content: (
        <ModalSelectPriod
          initialDate={initialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          setInitialDate={setInitialDate}
          onSelect={handleFilterPeriod}
        />
      ),
      title: 'Período',
      closeOnBackgroundClick: false,
      closeButton: false,
    })
  }

  useEffect(() => {
    if (!userCompaniesAccounts?.length) return

    // Agrupa as contas por nome da empresa
    const grouped = userCompaniesAccounts.reduce(
      (acc: Record<string, any[]>, current) => {
        const companyName = current.company.name
        if (!acc[companyName]) {
          acc[companyName] = []
        }
        acc[companyName].push(current)
        return acc
      },
      {},
    )

    // Normaliza os dados, incluindo o ID da empresa
    const normalized: CompanyAccount[] = Object.entries(grouped).map(
      ([companyName, accounts]) => {
        const companyId = accounts[0]?.company?.id

        return {
          id: companyId,
          label: companyName,
          subOptions: accounts.map((account: any) => ({
            id: account.id,
            name: account.name,
            amount: account.balance,
            agency: account.agencyNumber,
            account: account.accountNumber,
            bankName: account.bank,
            bankLogo: account.bankLogo,
          })),
        }
      },
    )

    // Adiciona empresas que não foram incluídas na normalização
    companies.forEach((company) => {
      if (!normalized.some((f) => f.id === company.id)) {
        normalized.push({
          id: company.id,
          label: company.name,
          subOptions: [],
        })
      }
    })

    setCompaniesAccounts(normalized)
  }, [userCompaniesAccounts, companies])

  const handleSelectAccount = (accountId: string) => {
    if (!accountsSelected.includes(accountId)) {
      setAccountsSelected([...accountsSelected, accountId])
    } else {
      setAccountsSelected(
        accountsSelected.filter((account) => account !== accountId),
      )
    }
  }

  const handleShowReports = () => {
    if (filterDates && filterDates.length > 0) {
      handleListTransfers({
        accountsIds: accountsSelected,
        date: filterDates,
      })
    } else {
      handleListTransfers({ accountsIds: accountsSelected })
    }
    handleListCashFlow({
      companiesIds: companies.map((company) => company.id),
      accountIds: accountsSelected,
      date: [],
      accountRequired: true,
    })
    setShowResults(true)
  }

  useEffect(() => {
    if (accountsSelected.length === 0) {
      return setShowResults(false)
    }
    setShowResults(false)
    if (filterDates && filterDates.length > 0) {
      handleListTransfers({
        accountsIds: accountsSelected,
        date: filterDates,
      })
    } else {
      handleListTransfers({ accountsIds: accountsSelected })
    }
    handleListCashFlow({
      companiesIds: companies.map((company) => company.id),
      accountIds: accountsSelected,
      date: filterDates,
      accountRequired: true,
    })
    setShowResults(true)
  }, [companies, accountsSelected, filterDates])

  useEffect(() => {
    const normalizeTransfers = transfers.map((transfer) => ({
      ...transfer,
      amountFormatted: formatMoney(Number(transfer.amount)),
      date: format(new Date(transfer.date), 'dd/MM/yyyy'),
    }))
    const extractBody: ExtractProps = {
      all: normalizeTransfers,
      income: normalizeTransfers?.filter(
        (transfer) => transfer.method === BankAccountTransferMethodEnum.CREDITO,
      ),
      outcome: normalizeTransfers?.filter(
        (transfer) => transfer.method === BankAccountTransferMethodEnum.DEBITO,
      ),
    }

    setExtract(extractBody)
  }, [transfers])

  const handleExport = async (selectedFormat: string, fullContent: boolean) => {
    if (!extract) {
      return ShowToast(
        'warning',
        'Nenhum registro disponível para ser impresso. ',
      )
    }
    const columns = [
      { header: 'Data', dataKey: 'date' },
      { header: 'Descrição', dataKey: 'description' },
      { header: 'Tipo', dataKey: 'type' },
      { header: 'Valor', dataKey: 'value' },
    ]

    const data = extract.all.map((extractItem) => {
      return {
        date: extractItem.date,
        description: extractItem.transferCompleteDescription,
        type:
          extractItem.method === BankAccountTransferMethodEnum.CREDITO
            ? 'Crédito'
            : 'Débito',
        value: extractItem.amountFormatted,
      }
    })
    if (selectedFormat === 'PDF') {
      const title = 'Visão Financeira'
      const subtitle = 'Extrato de Lançamentos'

      if (fullContent) {
        const input = document.getElementById('cashflowContent') as HTMLElement
        await generateCustomPDF({
          title,
          subtitle,
          columns,
          data,
          contentDiv: input,
        })
      } else {
        await generateCustomPDF({ title, subtitle, columns, data })
      }
    }
    if (selectedFormat === 'Excel') {
      const sheets = {
        sheetName: 'Extrato de Lançamentos',
        columns: columns.map((column) => column.header),
        data: data.map((item) => {
          return [item.date, item.description, item.type, item.value]
        }),
      }

      await generateXLS([sheets], 'Visão Financeira.xls')
    }
  }

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  return (
    <Stack
      width="100%"
      height="100%"
      sx={{ backgroundColor: '#F4F8FA', overflow: 'auto' }}
    >
      <DefaultHeader
        breadcumbItems={[
          { title: 'Painel multiempresas', navigateTo: '/home' },
          { title: 'Relatórios' },
        ]}
        buttons={
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>
          </Stack>
        }
      />

      <Stack
        width="100%"
        height="calc(100% - 180px)"
        direction="row"
        gap="24px"
      >
        <Stack
          width="100%"
          height="100%"
          padding="32px"
          paddingTop="12px"
          gap="48px"
        >
          <BaseInternalHeader
            leftComponent={
              <Button
                variant="text"
                startIcon={<ArrowBack />}
                onClick={() => navigate('/home')}
              >
                Voltar
              </Button>
            }
            rightComponent={
              <Stack flexDirection="row" alignItems="flex-start" gap="24px">
                <Button
                  variant="outlined"
                  endIcon={<CalendarIcon />}
                  sx={{ minWidth: '123px', height: '40px' }}
                  onClick={() => handleOpenPeriodModal()}
                >
                  <Typography
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="16.41px"
                  >
                    Período
                  </Typography>
                </Button>
                <Button
                  endIcon={<ArrowDropDown />}
                  variant="contained"
                  sx={{ width: '128px', height: '40px' }}
                  onClick={() => {
                    ShowModal({
                      content: <ModalReportsExport handle={handleExport} />,
                      title: 'Exportar',
                    })
                  }}
                >
                  Exportar
                </Button>
              </Stack>
            }
          />
          <Stack
            width="100%"
            height="40px"
            justifyContent="space-between"
            direction="row"
          >
            <BasePageTitle color="#1E3466" text="Visão financeira" />
          </Stack>

          <Stack width="100%" gap="20px" mt="-10px">
            <Typography
              fontSize="16px"
              fontWeight="700"
              lineHeight="21.79px"
              color="#0B1116"
            >
              Selecione uma ou mais empresas e contas para visualizar os
              detalhes
            </Typography>

            <Stack flexDirection="row" alignItems="flex-end" gap="18px">
              <Stack width="280px">
                <BaseMultipleSelectInput
                  label="Empresas"
                  labelBackground="transparent"
                  options={companiesAccounts?.map((company) => {
                    return { value: company.id, label: company.label }
                  })}
                  fullWidth
                  error=""
                  value={companiesSelected}
                  setState={(e) => setCompaniesSelected(e)}
                  allowSearchValues={true}
                />
              </Stack>
              <Button
                variant="contained"
                sx={{ width: '92px', height: '40px', marginBottom: '17px' }}
                onClick={handleShowReports}
              >
                Buscar
              </Button>
            </Stack>
          </Stack>

          <Stack mt="-40px" id="divToPrint">
            <Tabs value={tab} onChange={handleChange} aria-label="extract tabs">
              <Tab
                label={
                  <span
                    style={{
                      color: tab === 0 ? '#4865CC' : '#777C84',
                    }}
                  >
                    Relatório
                  </span>
                }
                {...tabProps(0)}
              />
              <Tab
                label={
                  <span
                    style={{
                      color: tab === 1 ? '#4865CC' : '#777C84',
                    }}
                  >
                    Contas
                  </span>
                }
                {...tabProps(1)}
              />
            </Tabs>
            {showResults && (
              <CustomTabPanel value={tab} index={0}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Stack id="cashflowContent">
                      <CashFlow
                        cashflowConsolidate={cashflowConsolidate}
                        variationData={variationData}
                        minVariation={minVariation}
                        maxVariation={maxVariation}
                        categoriesVariations={categoriesVariations}
                      />
                    </Stack>
                  </Grid>

                  <Grid item md={12}>
                    <Extract
                      key="extract-element"
                      data={extract}
                      balance={balance}
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
            )}
            <CustomTabPanel value={tab} index={1}>
              <Stack
                alignItems="flex-start"
                mt="-10px"
                width="calc(100% - 24px)"
              >
                {companiesAccounts
                  .filter((f) => companiesSelected.includes(f.id))
                  .map((company) => {
                    return (
                      <CompanySection key={company.label}>
                        <Typography
                          fontSize="24px"
                          fontWeight="700"
                          lineHeight="32.69px"
                          color="#0b1116"
                        >
                          {company.label}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="flex-start"
                          gap="24px"
                          padding="12px"
                          width="100%"
                          sx={{ overflow: 'auto' }}
                        >
                          {companiesAccounts
                            .filter((f) => f.id === company.id)
                            .map((company, index) => {
                              return company.subOptions.map(
                                (accountOptions) => {
                                  return (
                                    <BankAccountCard
                                      key={accountOptions.id}
                                      data={accountOptions}
                                      index={index}
                                      editable
                                      value={
                                        !!accountsSelected.find(
                                          (f) => f === accountOptions.id,
                                        )
                                      }
                                      editableClick={() =>
                                        navigate(
                                          `/company/${company.id}/account/edit/${accountOptions.id}`,
                                        )
                                      }
                                      selectable
                                      onSelect={() =>
                                        handleSelectAccount(accountOptions.id)
                                      }
                                      containerStyle={{
                                        width: '268px',
                                        height: '80px',
                                        justifyContent: 'flex-start',
                                      }}
                                      brandStyle={{
                                        width: '48px',
                                        height: '48px',
                                        marginLeft: '10px',
                                      }}
                                      amountStyle={{
                                        fontSize: '16px',
                                      }}
                                      agencyStyle={{
                                        marginTop: '3px',
                                      }}
                                      accountStyle={{
                                        marginTop: '3px',
                                      }}
                                    />
                                  )
                                },
                              )
                            })}
                        </Stack>
                      </CompanySection>
                    )
                  })}
              </Stack>
            </CustomTabPanel>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
