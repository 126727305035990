import { ArrowBack } from '@mui/icons-material'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import { DeleteTableIcon } from 'src/component/icons/delete'
import { PlanList } from 'src/component/plan/list-plans'
import { ICompanyPlan } from 'src/service/service-company'
import { CreateForm } from 'src/shared/util/form/form-util'
import { CompanyUseDetailsUseCase } from 'src/usecase/company/usecase-company-details'
import { ListCompanyPlansUseCase } from 'src/usecase/company/usecase-company-plans-list'
import { UpdateCompanyUseCase } from 'src/usecase/company/usecase-company-update'
import BlueHeader from '../../assets/background/header-blue.png'

interface CompanyEditProps {
  companyUseDetailsUseCase: CompanyUseDetailsUseCase
  updateCompanyUseCase: UpdateCompanyUseCase
  listCompanyPlansUseCase: ListCompanyPlansUseCase
}

export function CompanyEditPage(props: CompanyEditProps): any {
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [plans, setPlans] = useState<ICompanyPlan[]>([])
  const { companyId: currentCompanyId } = useParams()

  const updateForm = CreateForm({
    fields: ['name', 'cnpj'],
    validation: props.updateCompanyUseCase.validation,
  })

  const handleListPlansUseCase = async () => {
    try {
      setIsLoading(true)
      const plansResult = await props.listCompanyPlansUseCase.handle()

      if (plansResult.isFailure) {
        setIsLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
        )
      }

      const plansData = plansResult.getValue()
      setPlans(plansData as ICompanyPlan[])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
      )
    }
  }

  const handleGetCompany = async () => {
    try {
      setIsLoading(true)
      const result = await props.companyUseDetailsUseCase.handle({
        companyId: currentCompanyId as string,
      })

      if (result.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro retornar os dados da empresa. Tente novamente mais tarde.',
        )
        setIsLoading(false)
        return navigate('/home')
      }
      const data = result.getValue()
      updateForm.fields.name.setState(() => data?.name)
      updateForm.fields.cnpj.setState(() => data?.cnpj)
      setIsLoading(false)
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro retornar os dados dos usuários da empresa. Tente novamente mais tarde.',
      )
      setIsLoading(false)
      return navigate('/home')
    }
  }

  useEffect(() => {
    handleListPlansUseCase()
    handleGetCompany()
  }, [currentCompanyId])

  const handleUpdateCompanyUsecase = async () => {
    const data = {
      id: currentCompanyId as string,
      name: updateForm.fields.name.value,
      cnpj: updateForm.fields.cnpj.value,
    }

    const updateData = await props.updateCompanyUseCase.handle(data)

    if (updateData.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a atualização. Tente novamente mais tarde.',
      )
    } else {
      ShowToast('success', 'Dados atualizados com sucesso.')
    }
  }

  const handleValidate = async () => {
    try {
      const validationResult = await updateForm.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      handleUpdateCompanyUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a atualização. Tente novamente mais tarde.',
      )
    }
  }

  return (
    <Stack
      width="100%"
      height="100vh"
      sx={{ backgroundColor: '#F4F8FA' }}
      gap="24px"
    >
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Home', navigateTo: `/company/${currentCompanyId}` },
          { title: 'Editar Empresa' },
        ]}
      />
      <Stack direction="column" gap="24px" sx={{ overflow: 'hidden' }}>
        {isLoading && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && (
          <Stack
            width="100%"
            height="90%"
            gap="24px"
            padding="24px"
            paddingTop="0px"
          >
            <Stack width="100%">
              <BaseInternalHeader
                leftComponent={
                  <Button
                    variant="text"
                    startIcon={<ArrowBack />}
                    onClick={() => navigate(`/company/${currentCompanyId}`)}
                  >
                    Voltar
                  </Button>
                }
              />
              <Stack
                width="calc(100% - 48px)"
                height="100%"
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                alignContent="center"
              >
                <Typography fontWeight="700" fontSize="32px" color="#1E1E1E">
                  Editar dados da empresa
                </Typography>

                <Stack gap="24px" direction="row">
                  <Button
                    fullWidth={false}
                    variant="outlined"
                    // onClick={handleOpenPluggyConsentModal}
                    sx={{
                      padding: '12px 24px 12px 16px',
                      gap: '8px',
                      width: '240px',
                      height: '40px',
                      direction: 'row',
                    }}
                  >
                    <DeleteTableIcon />
                    Excluir empresa
                  </Button>
                  <Button
                    fullWidth={false}
                    variant="contained"
                    onClick={() =>
                      navigate(`/company/${currentCompanyId}/users/edit`)
                    }
                    sx={{
                      padding: '12px 24px 12px 16px',
                      gap: '8px',
                      width: '180px',
                      height: '40px',
                      direction: 'row',
                      color: 'white !important',
                    }}
                  >
                    <PeopleOutlinedIcon sx={{ color: 'white' }} />
                    Usuários
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              width="calc(100% - 48px)"
              maxWidth="1024px"
              height="100%"
              gap="24px"
              sx={{ overflowY: 'auto', overflowX: 'hidden' }}
            >
              <Stack
                width="calc(100% - 12px)"
                height="100%"
                paddingTop="12px"
                gap="24px"
                direction="row"
              >
                <BaseInput
                  label="Nome/Razão Social"
                  fullWidth
                  labelBackground="#F4F8FA"
                  {...updateForm.fields.name}
                />
                <BaseInput
                  label="CNPJ"
                  fullWidth
                  labelBackground="#F4F8FA"
                  {...updateForm.fields.cnpj}
                  mask={'99.999.999/9999-99'}
                />
              </Stack>

              <Stack paddingRight="12px" width="100%" gap="32px">
                <BasePageTitle color="#000000" text="Plano" />
                {/* <PlanList plans={plans} /> */}
              </Stack>

              <Stack
                width="calc(100% - 12px)"
                justifyContent="flex-end"
                alignItems="center"
                direction="row"
                gap="32px"
              >
                <Button
                  fullWidth={false}
                  variant="contained"
                  onClick={handleValidate}
                  sx={{
                    padding: '12px 24px 12px 16px',
                    gap: '8px',
                    width: '120px',
                    height: '40px',
                  }}
                >
                  Continuar
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
