import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { BaseInput } from 'src/component/base-component/base-input'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import SearchIcon from '@mui/icons-material/Search'
import { BaseDateSelect } from 'src/component/base-component/base-date-select'
import { useEffect, useState } from 'react'

export type ITableModalType =
  | 'only-text'
  | 'text-with-filter'
  | 'between-dates-filter'
  | 'between-values-filter'
  | 'select-options'

export const TableFilterModal: React.FC<{
  modalData: {
    modalType: ITableModalType
    options?: {
      label: string
      value: string
    }[]
    selectedOptions?: string[]
    setSelectedOptions?: React.Dispatch<
      React.SetStateAction<string[] | undefined>
    >
    searchQuery?: string
    setSearchQuery?: (value: React.SetStateAction<string>) => void
    initialDate: Date | undefined
    finalDate: Date | undefined
    handleSelectDate?: (e: Date | undefined, type: 'initial' | 'final') => void
    minValue?: string | undefined
    maxValue?: string | undefined
    setMinValue?: (value: React.SetStateAction<string>) => void
    setMaxValue?: (value: React.SetStateAction<string>) => void
  }
  handleApply: (
    currentInitialDate: Date | undefined,
    currentFinalDate: Date | undefined,
    currentMinValue: string | undefined,
    currentmMaxValue: string | undefined,
    currentSelectedOptions: string[] | undefined,
  ) => void
  handleCancel: () => void
}> = ({ modalData, handleCancel, handleApply }) => {
  const [internalControlSearchQuery, setInternalControlSearchQuery] = useState<
    string | undefined
  >(modalData?.searchQuery)
  const [internalControlInitialDate, setInternalControlInitialDate] = useState<
    Date | undefined
  >(modalData?.initialDate)
  const [internalControlFinalDate, setInternalControlFinalDate] = useState<
    Date | undefined
  >(modalData?.finalDate)
  const [internalControlMinValue, setInternalControlMinValue] = useState<
    string | undefined
  >(modalData?.minValue)
  const [internalControlMaxValue, setInternalControlMaxValue] = useState<
    string | undefined
  >(modalData?.maxValue)
  const [internalControlSelectedOptions, setInternalControlSelectedOptions] =
    useState<string[] | undefined>(modalData?.selectedOptions)

  const [optionFind, setOptionFind] = useState<string>('')

  const handleFinalize = () => {
    setOptionFind('')
    handleApply(
      internalControlInitialDate,
      internalControlFinalDate,
      internalControlMinValue,
      internalControlMaxValue,
      internalControlSelectedOptions,
    )
  }

  return (
    <Stack
      width="100%"
      gap="32px"
      maxHeight="50vh"
      position="relative"
      overflow="hidden"
    >
      <Stack
        sx={{
          padding: '0px 10px',
          width: '320px',
          maxHeight: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: '8px',
        }}
      >
        {/* Filtros que contenham descrição e permitam ordenação */}
        {/* ex: nomes de clientes */}
        {modalData.modalType === 'text-with-filter' && (
          <Stack
            width="100%"
            height="100%"
            sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            <Box width="95%" marginTop="20px" marginBottom="20px" />

            <Box paddingX="20px">
              <BaseInput
                fullWidth
                labelBackground="#F4F8FA"
                type="text"
                label="Buscar Empresa"
                setState={(event) => {
                  if (!event) {
                    setInternalControlSearchQuery('')
                    return (
                      modalData?.setSearchQuery && modalData?.setSearchQuery('')
                    )
                  }
                  setInternalControlSearchQuery(event)
                  modalData?.setSearchQuery && modalData?.setSearchQuery(event)
                }}
                value={internalControlSearchQuery ?? ''}
                error={null}
                iconStart={<SearchIcon />}
              />

              <Box marginTop="20px">
                {modalData?.options &&
                  modalData?.options.map((item) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={item.label}
                      marginBottom="10px"
                    >
                      <Checkbox
                        sx={{
                          color: '#4865CC',
                          '&.Mui-checked': {
                            color: '#4865CC',
                          },
                        }}
                        onClick={() => {
                          setInternalControlSelectedOptions((prevOptions) => {
                            const options = prevOptions || []

                            if (options.includes(item.value.toString())) {
                              return options.filter(
                                (option) => option !== item.value.toString(),
                              )
                            } else {
                              return [...options, item.value.toString()]
                            }
                          })
                          modalData?.setSelectedOptions &&
                            modalData?.setSelectedOptions((prevOptions) => {
                              const options = prevOptions || []
                              if (options.includes(item.value.toString())) {
                                return options.filter(
                                  (option) => option !== item.value.toString(),
                                )
                              } else {
                                return [...options, item.value.toString()]
                              }
                            })
                        }}
                        checked={
                          internalControlSelectedOptions?.includes(
                            item.value.toString(),
                          ) ?? false
                        }
                      />
                      <ListItemText
                        primary={item.label}
                        sx={{ marginLeft: '5px' }}
                      />
                    </Stack>
                  ))}
              </Box>
            </Box>
          </Stack>
        )}

        {/* Filtros que contenham opções */}
        {modalData.modalType === 'select-options' && (
          <Stack
            width="100%"
            height="100%"
            sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            <Box
              width="95%"
              borderBottom="dashed 1px #777C84"
              marginTop="20px"
              marginBottom="20px"
            />

            <Box paddingX="20px">
              <BaseInput
                fullWidth
                labelBackground="#F4F8FA"
                type="text"
                label="Buscar"
                setState={(event) => {
                  if (!event) {
                    return setOptionFind('')
                  }
                  setOptionFind(event)
                }}
                value={optionFind ?? ''}
                error={null}
                iconStart={<SearchIcon />}
              />

              <Box marginTop="20px">
                {modalData?.options &&
                  modalData?.options.map((item) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={item.label}
                      marginBottom="10px"
                    >
                      <Checkbox
                        sx={{
                          color: '#4865CC',
                          '&.Mui-checked': {
                            color: '#4865CC',
                          },
                        }}
                        onClick={() => {
                          setInternalControlSelectedOptions((prevOptions) => {
                            const options = prevOptions || []

                            if (options.includes(item.value.toString())) {
                              return options.filter(
                                (option) => option !== item.value.toString(),
                              )
                            } else {
                              return [...options, item.value.toString()]
                            }
                          })
                          modalData?.setSelectedOptions &&
                            modalData?.setSelectedOptions((prevOptions) => {
                              const options = prevOptions || []
                              if (options.includes(item.value.toString())) {
                                return options.filter(
                                  (option) => option !== item.value.toString(),
                                )
                              } else {
                                return [...options, item.value.toString()]
                              }
                            })
                        }}
                        checked={
                          internalControlSelectedOptions?.includes(
                            item.value.toString(),
                          ) ?? false
                        }
                      />
                      <ListItemText
                        primary={item.label}
                        sx={{ marginLeft: '5px' }}
                      />
                    </Stack>
                  ))}
              </Box>
            </Box>
          </Stack>
        )}

        {/* Filtros que precisam filtrar entre datas */}
        {modalData.modalType === 'between-dates-filter' && (
          <>
            <Typography
              color="#000"
              fontSize="14px"
              width="100%"
              fontWeight={600}
            >
              Selecione o período
            </Typography>
            <Stack direction="row" marginTop="20px">
              <BaseDateSelect
                title="De"
                onSelect={(e) => {
                  modalData?.handleSelectDate &&
                    modalData?.handleSelectDate(e, 'initial')
                  setInternalControlInitialDate(e)
                }}
                buttonStyle={{ width: '160px' }}
                containerStyle={{ width: '160px', marginRight: '15px' }}
                selectedDate={internalControlInitialDate}
                useCalendarPosition={false}
              />

              <BaseDateSelect
                title="Até"
                onSelect={(e) => {
                  modalData?.handleSelectDate &&
                    modalData?.handleSelectDate(e, 'final')
                  setInternalControlFinalDate(e)
                }}
                buttonStyle={{ width: '160px' }}
                containerStyle={{ width: '160px' }}
                selectedDate={internalControlFinalDate}
                useCalendarPosition={false}
              />
            </Stack>
          </>
        )}

        {/* Filtros que precisam filtrar entre valores */}
        {modalData.modalType === 'between-values-filter' && (
          <>
            <Typography color="#000" fontSize="14px" fontWeight={600}>
              Selecione o valor mínimo e máximo
            </Typography>
            <Stack direction="row" marginTop="20px">
              <BaseInput
                label="Mínimo"
                fullWidth
                type="text"
                setState={(e) => {
                  modalData?.setMinValue && modalData?.setMinValue(e)
                  setInternalControlMinValue(e)
                }}
                error=""
                value={internalControlMinValue as string}
                containerStyle={{ width: '140px', marginRight: '15px' }}
              />

              <BaseInput
                label="Máximo"
                type="number"
                fullWidth
                setState={(e) => {
                  modalData?.setMaxValue && modalData?.setMaxValue(e)
                  setInternalControlMaxValue(e)
                }}
                error=""
                value={internalControlMaxValue as string}
                containerStyle={{ width: '140px' }}
              />
            </Stack>
          </>
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          gap="12px"
          marginTop="20px"
        >
          <Button
            variant="outlined"
            sx={{ width: '104px', height: '40px', padding: 0 }}
            onClick={() => handleCancel()}
          >
            <Typography fontWeight="500" fontSize="14px" lineHeight="16.41px">
              Cancelar
            </Typography>
          </Button>

          <Button
            variant="contained"
            sx={{ width: '100px', height: '40px', padding: 0 }}
            onClick={handleFinalize}
          >
            <Typography fontWeight="500" fontSize="14px" lineHeight="16.41px">
              Aplicar
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
