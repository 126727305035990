import { ArrowBack, EditOutlined } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'

export function SupportPage() {
  const navigate = useNavigate()
  const theme = useTheme()
  const { ShowToast } = ToastFullContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const handleSendEmail = async () => {
    setIsLoading(true)
    if (!name || !email || !phone || !message) {
      return ShowToast('warning', 'Todos os campos devem ser preenchidos')
    }

    const body = `Nome: ${name}%0A` + `Telefone: ${phone}%0A%0A` + `${message}`
    const mailtoLink = `mailto:suporte@openi.com.br?subject=Suporte%20Openi&body=${body}&from=${email}`
    window.location.href = mailtoLink
    setIsLoading(false)
  }

  const handleCancel = () => {
    navigate(`/home`)
  }

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        breadcumbItems={[
          { title: 'Painel multiempresas', navigateTo: '/home' },
          { title: 'Suporte' },
        ]}
      />

      <Stack
        width="100%"
        height="calc(100% - 130px)"
        direction="row"
        gap="32px"
        overflow="hidden"
      >
        {isLoading && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && (
          <Stack
            width="100%"
            maxWidth="1024px"
            padding="32px"
            height="100%"
            gap="24px"
            direction="column"
          >
            <BaseInternalHeader
              leftComponent={
                <Button
                  variant="text"
                  startIcon={<ArrowBack />}
                  onClick={() => navigate('/home')}
                >
                  Voltar
                </Button>
              }
            />
            <Stack
              width="100%"
              justifyContent="space-between"
              direction="row"
              alignItems="center"
              alignContent="center"
            >
              <Typography fontWeight="700" fontSize="32px" color="#1E1E1E">
                Suporte
              </Typography>
            </Stack>

            <Stack width="100%" gap="24px">
              <BaseInput
                label="Nome"
                fullWidth
                iconEnd={
                  <EditOutlined style={{ color: theme.palette.primary.main }} />
                }
                error=""
                value={name}
                setState={(e) => setName(e)}
              />
              <BaseInput
                label="Email"
                fullWidth
                error=""
                value={email}
                setState={(e) => setEmail(e)}
              />
              <BaseInput
                label="Telefone"
                fullWidth
                error=""
                value={phone}
                mask="(99) 99999-9999"
                setState={(e) => setPhone(e)}
              />
              <BaseInput
                label="Mensagem"
                fullWidth
                error=""
                value={message}
                lines={6}
                multiline
                setState={(e) => setMessage(e)}
              />
            </Stack>

            <Stack
              width="100%"
              maxWidth="1024px"
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
              gap="32px"
            >
              <Button
                fullWidth={false}
                onClick={handleCancel}
                variant="outlined"
                sx={{
                  padding: '12px 24px 12px 16px',
                  gap: '8px',
                  width: '110px',
                  height: '40px',
                }}
              >
                Cancelar
              </Button>
              <Button
                fullWidth={false}
                variant="contained"
                onClick={handleSendEmail}
                sx={{
                  padding: '12px 24px 12px 16px',
                  gap: '8px',
                  width: '180px',
                  height: '40px',
                }}
              >
                {isLoading ? <CircularProgress /> : 'Enviar'}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
