import { Button, IconButton, Stack, Typography } from '@mui/material'
import { PlanIcon } from '../icons/plan'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useState } from 'react'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'

export type IVariablePlanType = {
  name: string
  value: string[] | null
  enabled: boolean
}

export type PlanSubscriptionCardProps = {
  id: string
  title: string
  value: string
  variables: string[]
  data: IVariablePlanType[] | undefined
  selection: {
    id: string | null
    licenses: number
    alreadyExistPlanLicenses?: number
    availableLicenses?: number | null
  }[]
  selectionButtonAction?: (
    selection: { id: string | null; licenses: number }[],
  ) => void
  availableLicenses?: number
  isPlanSelectable?: boolean
  alreadyExistPlanLicenses?: number
}

export const PlanCardSubscription = ({
  id,
  title,
  value,
  data,
  selection = [],
  selectionButtonAction,
  availableLicenses = 0,
  isPlanSelectable = true,
  alreadyExistPlanLicenses,
}: PlanSubscriptionCardProps) => {
  const selectedPlan = selection.find((plan) => plan.id === id)
  const initialLicenses = selectedPlan?.licenses || 0
  const [licenses, setLicenses] = useState<number>(initialLicenses)

  const updateSelection = (updatedLicenses: number) => {
    // Remove planos com id nulo ou que são o plano atual
    const updatedSelection = selection.filter(
      (plan) => plan.id && plan.id !== id,
    )

    // Adiciona o plano atual com a quantidade de licenças atualizada
    // if (updatedLicenses > 0) {
    //   updatedSelection.push({ id, licenses: updatedLicenses })
    // }
    updatedSelection.push({ id, licenses: updatedLicenses })

    selectionButtonAction && selectionButtonAction(updatedSelection)
  }

  const handleAddLicense = () => {
    const isFirstSelection = selection.every((plan) => plan.id === null)
    const newLicenses = licenses === 0 && isFirstSelection ? 10 : licenses + 1
    setLicenses(newLicenses)
    updateSelection(newLicenses)
  }

  const handleRemoveLicense = () => {
    if (licenses > 0) {
      const newLicenses = alreadyExistPlanLicenses
        ? Math.max(alreadyExistPlanLicenses, licenses - 1)
        : licenses - 1
      setLicenses(newLicenses)
      updateSelection(newLicenses)
    }
  }

  return (
    <Stack
      width="100%"
      maxWidth="calc(50% - 24px - 24px - 24px)"
      justifyContent="space-between"
      height="auto"
      borderRadius="8px"
      border="1px solid #B9BFC7"
      padding="24px"
    >
      <Stack
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        borderBottom="1px solid #B9BFC7"
      >
        <Stack gap="12px" direction="row" alignItems="center">
          <PlanIcon />
          <Typography
            key={`plan-card-${title}`}
            fontWeight="700"
            fontSize="24px"
            gap="12px"
          >
            {title}
          </Typography>
        </Stack>

        <Typography
          key={`plan-card-subtitle-${id}`}
          fontWeight="400"
          fontSize="14px"
          textAlign="right"
        >
          {value
            ? formatMoney(Number(value))
            : 'Fale conosco e receba um orçamento personalizado'}
        </Typography>
      </Stack>

      <Stack
        gap="16px"
        marginTop="16px"
        width="100%"
        justifyContent="flex-start"
      >
        {data?.map((option) => {
          return (
            <Typography
              display="flex"
              width="100%"
              key={`plan-card-${option.name}-${title}`}
              fontWeight="400"
              fontSize="14px"
              gap="12px"
              justifyContent="flex-start"
              alignItems="center"
              color="#1E1E1E"
              sx={{
                '.MuiSvgIcon-root': {
                  fill: option.enabled ? '#057D88' : '#D52020',
                  color: option.enabled ? '#057D88' : '#D52020',
                },
              }}
            >
              {option.enabled ? (
                <CheckCircleOutlineIcon />
              ) : (
                <HighlightOffIcon />
              )}
              <Stack
                direction="row"
                gap="8px"
                minWidth="180px"
                alignItems="flex-start"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {option.name}
                </Typography>
              </Stack>
              {option.value && (
                <Stack
                  direction={option.value.length > 1 ? 'column' : 'row'}
                  gap="8px"
                  alignItems="flex-start"
                >
                  {option.value.map((text, index) => (
                    <Typography
                      flexDirection="row"
                      key={`${index}-${title}`}
                      fontSize="14px"
                    >
                      {option.value && option.value.length > 1 ? '• ' : ''}
                      {text}
                    </Typography>
                  ))}
                </Stack>
              )}
            </Typography>
          )
        })}
      </Stack>

      {!isPlanSelectable && (
        <Stack width="100%" justifyContent="center" alignItems="center">
          <Button
            variant={'outlined'}
            sx={{ width: '220px', marginTop: '12px' }}
          >
            Solicite um orçamento
          </Button>
        </Stack>
      )}

      {isPlanSelectable && (
        <Stack
          width="100%"
          justifyContent="center"
          alignItems="center"
          direction="row"
          gap="12px"
          marginTop="12px"
        >
          <Stack sx={{ border: '1px solid green' }}>
            <IconButton onClick={handleRemoveLicense} disabled={licenses === 0}>
              <RemoveIcon />
            </IconButton>
          </Stack>
          <Typography>{licenses}</Typography>
          <Stack sx={{ border: '1px solid green' }}>
            <IconButton onClick={handleAddLicense}>
              <AddIcon />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
