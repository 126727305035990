import { CircularProgress, Stack, Typography } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  GridPaginationModel,
  GridLocaleText,
} from '@mui/x-data-grid'
import React, { useState } from 'react'
import { IListCompanyFilterDTO } from 'src/service/service-company'
import { TableHeader } from './header'

const localePTBR: Partial<GridLocaleText> = {
  noRowsLabel: 'Nenhuma linha',
  noResultsOverlayLabel: 'Nenhum resultado encontrado.',
  toolbarDensity: 'Densidade',
  toolbarDensityLabel: 'Densidade',
  toolbarDensityCompact: 'Compacta',
  toolbarDensityStandard: 'Padrão',
  toolbarDensityComfortable: 'Confortável',
  footerRowSelected: (count) =>
    `${count.toLocaleString()} linha(s) selecionada(s)`,
  footerTotalRows: 'Total de linhas:',
  MuiTablePagination: {
    labelRowsPerPage: '',
    labelDisplayedRows: ({ from, to, count }) =>
      `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`,
  },
}

export const columnPadding = 32
export const ColumnsCommon: GridColDef = {
  headerAlign: 'left',
  align: 'left',
  headerName: 'Placeholder',
  flex: 1,
  headerClassName: 'super-app-theme--header',
  cellClassName: 'super-app-theme--normal-column',
  disableColumnMenu: true,
  disableReorder: true,
  disableExport: true,
  filterable: false,
  resizable: false,
  sortable: false,
  field: 'Placeholder',
}

export type TableProps = {
  rows: any[]
  columns: GridColDef[]
  loading: boolean
  allowSelectAll: boolean
  loadMore: (page: number) => void
  showSearchButton?: boolean
  searchQuery?: string
  setSearchQuery?: React.Dispatch<React.SetStateAction<string>>
  showFilterButton?: boolean
  filter?: IListCompanyFilterDTO
  setFilter?: React.Dispatch<React.SetStateAction<IListCompanyFilterDTO>>
  handleOpenFilterModal?: () => void
  selectedItemActions?: {
    title: string
    action: (selectedItems: number[]) => void
    disabled?: boolean
  }[]
  setSelectedGridIds?: React.Dispatch<React.SetStateAction<string[]>>
  rightActions?: React.ReactNode
  leftActions?: React.ReactNode
  checkboxSelection?: boolean
  tableStyle?: object
  tableContainerStyle?: object
  currentPage: number
  totalRegisters: number
  totalPages: number
  showNoResultsOverlay?: boolean
}

export const Table = ({
  rows,
  columns,
  loading,
  totalRegisters,
  totalPages,
  currentPage = 1,
  loadMore,
  selectedItemActions = [],
  filter,
  searchQuery,
  setFilter,
  setSearchQuery,
  setSelectedGridIds,
  showFilterButton = false,
  showSearchButton = false,
  handleOpenFilterModal,
  rightActions,
  leftActions,
  checkboxSelection = true,
  tableStyle = {},
  tableContainerStyle = {},
  showNoResultsOverlay = true,
}: TableProps) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: currentPage - 1,
    pageSize: 10,
  })

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    const selectedRows = newSelection.map((id) =>
      rows.find((row: any) => row.id === id),
    )
    setSelectedItems(selectedRows)
    setSelectedGridIds &&
      setSelectedGridIds(selectedRows?.map((row) => row.id) ?? [])
  }

  const handlePaginationChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
    loadMore(model.page + 1)
  }

  return (
    <Stack width="100%" gap="32px" sx={tableContainerStyle}>
      <TableHeader
        selectedItems={selectedItems}
        filter={filter}
        handleOpenFilterModal={handleOpenFilterModal}
        searchQuery={searchQuery}
        selectedItemActions={selectedItemActions}
        setFilter={setFilter}
        setSearchQuery={setSearchQuery}
        showFilterButton={showFilterButton}
        showSearchButton={showSearchButton}
        rightActions={rightActions}
        leftActions={leftActions}
      />

      <div
        style={{
          height: 'auto',
          maxHeight: 'calc(100% - 56px)',
          overflowY: 'auto',
          width: 'calc(100% - 8px)',
          paddingRight: '8px',
          overflow: 'auto',
          backgroundColor: 'transparent',
          border: 'none !important',
        }}
      >
        <DataGrid
          sx={{
            height: '100%',
            backgroundColor: '#ffffff',
            border: 'none !important',
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1000,
              backgroundColor: '#5ED1A2',
            },
            '& .MuiCheckbox-root': {
              color: '#4865CC !important',
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#ffffff !important',
            },
            '& .super-app-theme--header': {
              fontFamily: 'Noto Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              height: 56,
              textAlign: 'center',
              color: '#ffffff',
              gap: '4px',
              wordBreak: 'break-word',
              backgroundColor: '#5ED1A2',
              border: 'none !important',
              borderBottom: 'none !important',
              borderRadius: '0px',
              borderTop: 'none',
              ':first-child': {
                borderLeft: 'none',
              },
              ':last-child': {
                borderTopRightRadius: '16px ',
              },
            },
            '& .super-app-theme--normal-column .MuiDataGrid-cellCheckbox': {
              fontFamily: 'Noto Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 14,
              height: 56,
              borderTopLeftRadius: 10,
              textAlign: 'left',
              color: '#383838',
              backgroundColor: '#ffffff !important',
              borderRadius: '0px',
              border: 'none',
              borderBottom: '1px solid #DFEAED',
              ':first-child': {
                borderLeft: 'none',
              },
              ':last-child': {
                borderRight: 'none',
              },
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              fontFamily: 'Noto Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 16,
              height: 56,
              textAlign: 'center',
              color: '#ffffff',
              gap: '4px',
              backgroundColor: '#5ED1A2',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#ffffff !important',
            },
            '& .MuiDataGrid-footerContainer': {
              position: 'sticky',
              bottom: 0,
              backgroundColor: '#ffffff',
              zIndex: 1000,
            },
            '& .MuiTablePagination-selectRoot': {
              display: 'none', // Oculta a dropdown de seleção de registros por página
            },
            ...tableStyle,
          }}
          rows={rows ?? []}
          columns={columns}
          loading={loading}
          checkboxSelection={checkboxSelection}
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={
            selectedItems.map((item) => item.id) as GridRowId[]
          }
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          pageSizeOptions={[10]}
          rowCount={totalRegisters}
          localeText={localePTBR}
          slots={{
            noRowsOverlay: () => (
              <Stack
                position="relative"
                height="100%"
                alignItems="center"
                justifyContent="flex-start"
                marginTop="50px"
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '-45px',
                    }}
                  >
                    Não há nenhum registro cadastrado no momento
                  </Typography>
                )}
              </Stack>
            ),
            noResultsOverlay: () => (
              <Stack
                position="relative"
                height="100%"
                alignItems="center"
                justifyContent="flex-start"
                marginTop="50px"
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '-45px',
                    }}
                  >
                    Nenhum resultado encontrado.
                  </Typography>
                )}
              </Stack>
            ),
          }}
        />
      </div>
    </Stack>
  )
}
