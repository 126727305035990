import { Button, Stack, Typography } from '@mui/material'
import { PlanIcon } from '../icons/plan'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'

export type IVariablePlanType = {
  name: string
  value: string[] | null
  enabled: boolean
}

export type PlanCardProps = {
  id: string
  title: string
  value: string
  variables: string[]
  data: IVariablePlanType[] | undefined
  selectedPlan?: string
  selectionButtonAction?: (id: string) => void
  availableLicenses?: number
  isPlanSelectable?: boolean
}

export const PlanCard = ({
  id,
  title,
  value,
  data,
  selectedPlan,
  selectionButtonAction,
  availableLicenses = 0,
  isPlanSelectable = true,
}: PlanCardProps) => {
  return (
    <Stack
      width="100%"
      maxWidth="calc(50% - 24px - 24px - 24px)"
      justifyContent="space-between"
      height="auto"
      borderRadius="8px"
      border="1px solid #B9BFC7"
      padding="24px"
    >
      <Stack
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        borderBottom="1px solid #B9BFC7"
      >
        <Stack gap="12px" direction="row" alignItems="center">
          <PlanIcon />
          <Typography
            key={`plan-card-${title}`}
            fontWeight="700"
            fontSize="24px"
            gap="12px"
          >
            {title}
          </Typography>
        </Stack>

        <Typography
          key={`plan-card-subtitle-${id}`}
          fontWeight="400"
          fontSize="14px"
          textAlign="right"
        >
          {`Licenças disponíveis: ${availableLicenses}`}
        </Typography>
        {/* <Typography
          key={`plan-card-subtitle-${id}`}
          fontWeight="400"
          fontSize="14px"
          textAlign="right"
        >
          {value
            ? formatMoney(Number(value))
            : 'Fale conosco e receba um orçamento personalizado'}
        </Typography> */}
      </Stack>

      <Stack
        gap="16px"
        marginTop="16px"
        width="100%"
        justifyContent="flex-start"
      >
        {data?.map((option) => {
          return (
            <Typography
              display="flex"
              width="100%"
              key={`plan-card-${option.name}-${title}`}
              fontWeight="400"
              fontSize="14px"
              gap="12px"
              justifyContent="flex-start"
              alignItems="center"
              color="#1E1E1E"
              sx={{
                '.MuiSvgIcon-root': {
                  fill: option.enabled ? '#057D88' : '#D52020',
                  color: option.enabled ? '#057D88' : '#D52020',
                },
              }}
            >
              {option.enabled ? (
                <CheckCircleOutlineIcon />
              ) : (
                <HighlightOffIcon />
              )}
              <Stack
                direction="row"
                gap="8px"
                minWidth="180px"
                alignItems="flex-start"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {option.name}
                </Typography>
              </Stack>
              {option.value && (
                <Stack
                  direction={option.value.length > 1 ? 'column' : 'row'}
                  gap="8px"
                  alignItems="flex-start"
                >
                  {option.value.map((text, index) => (
                    <Typography
                      flexDirection="row"
                      key={`${index}-${title}`}
                      fontSize="14px"
                    >
                      {option.value && option.value.length > 1 ? '• ' : ''}
                      {text}
                    </Typography>
                  ))}
                </Stack>
              )}
            </Typography>
          )
        })}
      </Stack>

      {!isPlanSelectable && (
        <Stack width="100%" justifyContent="center" alignItems="center">
          <Button
            variant={id === selectedPlan ? 'contained' : 'outlined'}
            sx={{ width: '220px', marginTop: '12px' }}
          >
            Solicite um orçamento
          </Button>
        </Stack>
      )}

      {isPlanSelectable && (
        <Stack
          width="100%"
          justifyContent="center"
          alignItems="center"
          onClick={() => selectionButtonAction && selectionButtonAction(id)}
        >
          <Button
            disabled={availableLicenses === 0}
            variant={id === selectedPlan ? 'contained' : 'outlined'}
            sx={{ width: '220px', marginTop: '12px' }}
          >
            Selecionar plano
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
