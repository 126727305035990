import { Stack } from '@mui/material'
import { PlanCardSubscription } from 'src/component/plan/subscription-card'

export const variables = [
  'Conexão Open Finance',
  'Automatizações incluídas',
  'Sistemas e ERPs',
  'Layouts personalizados',
  'Suporte prioritário',
  'Integrações personalizadas',
  'Workflow de Automatizações',
]

export const variablesContent = [
  {
    plan: 'Basic',
    content: [
      {
        name: 'Conexão Open Finance',
        value: ['Não incluso'],
        enabled: false,
      },
      {
        name: 'Automatizações incluídas',
        value: [
          'Classificador Contábil e Financeiro',
          'Gestão de Fluxo de Caixa',
        ],
        enabled: true,
      },
      {
        name: 'Sistemas e ERPs',
        value: [
          'Alterdata - Pack, Dexion, Fortes, Mastermaq, Prosoft, WK, Questor, Nasajon, Domínio, Conta Azul, Nibo, Omie, Bling, Tiny, Superlógica Condomínios, Superlógica Imobiliária, Superlógica Empresas',
        ],
        enabled: true,
      },
      {
        name: 'Layouts personalizados',
        value: null,
        enabled: false,
      },
      {
        name: 'Suporte prioritário',
        value: null,
        enabled: false,
      },
      {
        name: 'Integrações personalizadas',
        value: null,
        enabled: false,
      },
      {
        name: 'Workflow de Automatizações',
        value: null,
        enabled: false,
      },
    ],
  },
  {
    plan: 'Pro',
    content: [
      {
        name: 'Conexão Open Finance',
        value: ['3 contas conectadas', '2 atualizações por dia'],
        enabled: true,
      },
      {
        name: 'Automatizações incluídas',
        value: ['Tudo do plano Basic +', 'Conexão Open Finance'],
        enabled: true,
      },
      {
        name: 'Sistemas e ERPs',
        value: [
          'Todos os anteriores + Senior, MXM, Tron, Data C, Siescon, RTA sistemas, Grupo módulos, Oneflow, E-Contab, Systempro, Contsimples, Calima ERP, Linx, Alterdata - Bimer, Grupo Click, Yampa',
        ],
        enabled: true,
      },
      {
        name: 'Layouts personalizados',
        value: null,
        enabled: true,
      },
      {
        name: 'Suporte prioritário',
        value: null,
        enabled: false,
      },
      {
        name: 'Integrações personalizadas',
        value: null,
        enabled: false,
      },
      {
        name: 'Workflow de Automatizações',
        value: null,
        enabled: false,
      },
    ],
  },
  {
    plan: 'Premium',
    content: [
      {
        name: 'Conexão Open Finance',
        value: ['10 contas conectadas', '4 atualizações por dia'],
        enabled: true,
      },
      {
        name: 'Automatizações incluídas',
        value: ['Tudo do plano Pro +', 'Conciliador de provisão e baixa'],
        enabled: true,
      },
      {
        name: 'Sistemas e ERPs',
        value: ['Todos os anteriores + Totsv, SAP'],
        enabled: true,
      },
      {
        name: 'Layouts personalizados',
        value: null,
        enabled: true,
      },
      {
        name: 'Suporte prioritário',
        value: null,
        enabled: true,
      },
      {
        name: 'Integrações personalizadas',
        value: null,
        enabled: false,
      },
      {
        name: 'Workflow de Automatizações',
        value: null,
        enabled: false,
      },
    ],
  },
  {
    plan: 'Enterprise',
    content: [
      {
        name: 'Conexão Open Finance',
        value: ['Contas ilimitadas', 'O limite máximo'],
        enabled: true,
      },
      {
        name: 'Automatizações incluídas',
        value: ['Tudo do plano Premium'],
        enabled: true,
      },
      {
        name: 'Sistemas e ERPs',
        value: ['Todos os sistemas ERPs'],
        enabled: true,
      },
      {
        name: 'Layouts personalizados',
        value: null,
        enabled: true,
      },
      {
        name: 'Suporte prioritário',
        value: null,
        enabled: true,
      },
      {
        name: 'Integrações personalizadas',
        value: null,
        enabled: true,
      },
      {
        name: 'Workflow de Automatizações',
        value: null,
        enabled: true,
      },
    ],
  },
]

const order = ['Basic', 'Pro', 'Premium', 'Enterprise']

export type PlanListSubscriptiionProps = {
  plans: {
    id: string
    name: string
    value: string
    selection?: {
      id: string | null
      licenses: number | null
      alreadyExistPlanLicenses?: number
      availableLicenses?: number | null
    }
  }[]
  selectionButtonAction?: (
    selection: { id: string | null; licenses: number }[],
  ) => void
}

export const PlanListSubscription = ({
  plans = [],
  selectionButtonAction,
}: PlanListSubscriptiionProps) => {
  return (
    <Stack
      width="100%"
      height="100%"
      direction="row"
      gap="32px"
      overflow="auto"
      paddingBottom="12px"
      sx={{
        flexWrap: 'wrap',
        '& > .MuiPaper-root': {
          width: 'calc(50% - 8px)',
          minWidth: '150px',
          marginBottom: '16px',
        },
      }}
    >
      {plans
        .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name))
        .map((plan, index) => {
          const selectionArray = plan.selection
            ? [
                {
                  id: plan.selection.id,
                  licenses: plan.selection.licenses ?? 0,
                },
              ]
            : []

          return (
            <PlanCardSubscription
              id={plan.id}
              title={plan.name}
              value={plan.value}
              key={index}
              selectionButtonAction={selectionButtonAction}
              variables={variables}
              data={variablesContent.find((f) => f.plan === plan.name)?.content}
              selection={selectionArray}
              availableLicenses={plan.selection?.licenses ?? 0}
              isPlanSelectable={plan.name !== 'Enterprise'}
            />
          )
        })}
    </Stack>
  )
}
